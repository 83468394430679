import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FF2A97",
    },
    secondary: {
      main: "#FD2B88",
    },
    // error: {
    //   main: "rgba(255,0,64,0.6)",
    // },
    // background: {
    //   default: "#09092D",
    // },
  },

  
});

export default theme;
