import React, { Component } from "react";
import "../../Styles/app.scss";
import Button from "@material-ui/core/Button";
import Header from "../../Layout/header";
export class Landing extends Component {
  render() {
    return (
      <div>
        <div className="landing-container">
          <Header></Header>
          <div className="jumbotron">
            <div className="main-heading">
              The <span>future</span> of healthcare.
            </div>
            <div className="sub-heading">
              <span>One platform for all your staffing and HR needs.</span>
              <span>
                Hire Credentialed and verfied nurses with the click of button.
              </span>
            </div>
            <div className="landing-cta">
            <Button variant="outlined" color="primary" style={{marginRight:10, width:230}}>
                For Facilities
              </Button>
              <Button variant="contained" color="primary" style={{width:230}}>
                For Nurses
              </Button>
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Landing;
