import Index from "./App/Pages/index";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./App/Styles/appTheme";
function App() {
  return (
    <div className="App">
         <ThemeProvider theme={theme}>
         <Index></Index>
         </ThemeProvider>
      
    </div>
  );
}

export default App;
