import React, { Component } from 'react'
import Landing from './Landing/landing';
export class Index extends Component {
    render() {
        return (
            <div>
               <Landing></Landing> 
            </div>
        )
    }
}

export default Index
