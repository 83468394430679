import React, { Component } from "react";
import Logo from "../Assets/icons/logo.svg";
import Button from '@material-ui/core/Button';
import "../Styles/app.scss";
export class Header extends Component {
  render() {
    return (
      <div>
        <div className="header">
          <div className="hls">
            <div className="logo">
              <div className="logo-image">
                <img src={Logo} alt="logo"/>
              </div>
            </div>
          </div>
          <div className="hrs">
            <div className="header-cta">
            <Button className="header-btn" style={{textTransform: "capitalize"}}>Home</Button>
            <Button className="header-btn" style={{textTransform: "capitalize"}}>How it works</Button>
            <Button className="header-btn" style={{textTransform: "capitalize"}}>Services</Button>
            <Button className="header-btn" style={{textTransform: "capitalize"}}>Contact Us</Button>
            <Button variant="contained" size="medium" color="primary" className="round-btn header-btn" style={{textTransform: "capitalize"}}>
          Login
        </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
